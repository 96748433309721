<template>
  <Popover v-slot="{ open }" class="flex">
    <PopoverButton
      :disabled="isDisabled"
      :class="[
          isDisabled ? 'text-gray-500 dark:text-gray-500 bg-gray-100 dark:bg-gray-200 cursor-not-allowed' : 'text-white border-0 bg-sky-red-500 hover:bg-sky-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-red-500',
          open ? '' : 'text-opacity-90'
      ]"
      class="flex flex-grow items-center justify-around px-4 py-2 border w-100 border-transparent rounded-md shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
    >
        <div>{{ label }}</div>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
          static
          v-if="open"
          class="
            fixed
            z-20
            px-4
            sm:px-0
            top-1/2
            left-1/2
            -translate-x-1/2
            -translate-y-1/2
          "
        :class="{'top-1/3 left-0 right-0': centerConfirmation}, {'w-full transform -translate-x-44': !centerConfirmation}"
      >
        <div
          class="
            overflow-hidden
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            w-64
            max-w-64
            lg:max-w-3xl
          "
          :class="{'ml-auto mr-auto': centerConfirmation}"
        >
          <div class="relative grid bg-white dark:bg-gray-700 dark:text-gray-100 p-4 pb-2 text-center">
              {{ message }}
          </div>
          <div class="p-4 grid grid-cols-2 gap-2 justify-between bg-white dark:bg-gray-800">
              <PopoverButton
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 justify-center text-gray-500 bg-gray-100 hover:bg-gray-300 hover:text-gray-900"
                @click.stop
              >
                No
              </PopoverButton>
              <PopoverButton
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 justify-center text-white bg-red-600 hover:bg-red-700 hover:bg-red-700"
                @click.stop="$emit('onDelete')"
              >
                Yes
              </PopoverButton>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Popover, PopoverButton, PopoverPanel },
  props: {
      message: {
          type: String,
          required: true
      },
      centerConfirmation: {
          type: Boolean,
          required: false,
          default: false
      },
      isDisabled: {
          type: Boolean,
          default: false,
          required: false
      },
      label: {
          type: String,
          default: "Delete",
          required: false
      }
  },
  emits: [ "onDelete" ],
  setup() {
      return {
          open: false
      }
  },
});
</script>
