<template>
    <Form @submit="onSubmit">
        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2 divide divide-y">
                <FormComponent :hasTopPadding="false">
                    <TextInput
                        id="cat-name"
                        label="Name"
                        placeholder="Type"
                        :isEditing="isEditing"
                        :value="name"
                        :errors="nameError"
                        :required="true"
                        @update:value="v => (name = v)"
                    />
                </FormComponent>

                <FormComponent>
                    <MoneyInput
                        label="Fixed Price"
                        :isEditing="isEditing"
                        :value="pricePerMinute"
                        :errors="pricePerMinuteError"
                        :required="true"
                        :max-value="10000"
                        @update:value="v => (pricePerMinute = v)"
                    />
                </FormComponent>

                <FormComponent v-if="isDefaultSettable">
                  <FormCheckbox
                    id="set-as-default"
                    label="Is Default?"
                    :isEditing="isEditing"
                    :value="setAsDefault"
                    :errors="setAsDefaultError"
                    :required="true"
                    @update:value="v => (setAsDefault = v)"
                  />
                </FormComponent>
            </div>

        </div>
    </Form>
</template>

<script lang="ts">
import {defineComponent, inject, onErrorCaptured, PropType, ref} from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import DogCategory from "../types/DogCategory";
import FormCheckbox from "../../../components/forms/FormCheckbox.vue";

export default defineComponent({
  components: {FormCheckbox},
    props: {
        catToEdit: {
            type: Object as PropType<DogCategory | undefined>,
            required: false,
            default: undefined
        },
        isEditing: {
            type: Boolean,
            required: true
        },
        allowNegativePrice: {
            type: Boolean,
            required: false,
            default: false
        },
        isDefaultSettable: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props, context) {
        const isDefaultSettable = ref(props.isDefaultSettable);
        const pricePerMinuteRequiredCheck = yup.number()
            .required("A fixed price is required")
            .min(-10000, "Price must be greater than -10,000")
            .max(10000, "Price must be less than 10,000");
        const pricePerMinuteMinCheck = yup.number()
            .min(0, "Price must be a positive value")
            .max(10000, "Price must be less than 10,000");

        const schema = yup.object({
            id: yup.string().nullable(),
            name: yup.string().required("Name is required.").max(255),
            price_per_minute: props.allowNegativePrice ? pricePerMinuteRequiredCheck : pricePerMinuteRequiredCheck.concat(pricePerMinuteMinCheck),
            set_as_default: yup.boolean()
        });

        const { handleSubmit } = useForm({
            validationSchema: schema,
        })

        const { value: id } = useField("id");
        const { value: name, errorMessage: nameError } = useField("name");
        const { value: pricePerMinute, errorMessage: pricePerMinuteError } = useField("price_per_minute");
        const { value: setAsDefault, errorMessage: setAsDefaultError } = useField("set_as_default");

        if (props.catToEdit) {
            name.value = props.catToEdit.name;
            pricePerMinute.value = props.catToEdit.price_per_minute;
            setAsDefault.value = props.catToEdit.set_as_default && props.catToEdit.set_as_default > 0;
            id.value  = props.catToEdit.id;
        }
        else {
          setAsDefault.value = false;
        }

        const onSubmit = handleSubmit(values => {
            context.emit("onSubmit", values);
        });

        return {

            name,
            nameError,

            pricePerMinute,
            pricePerMinuteError,

            setAsDefault,
            setAsDefaultError,
            
            onSubmit,
            isDefaultSettable
        }
    },
})
</script>
