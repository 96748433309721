<template>
  <label :for="id" class="block font-medium text-gray-700 dark:text-gray-100">
    {{ label }}{{ required && isEditing ? "*" : "" }}
  </label>
  <input
    v-if="isEditing"
    v-model="internalValue"
    :disabled="!isEditing"
    :id="id"
    :name="id"
    type="checkbox"
    class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
  >
  <label v-else class="block text-gray-700 dark:text-gray-100">
    {{ value ? "Yes":"No" }}
  </label>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
    props: {
        label:{
            required: true,
            type: String,
        },

        value:{
            required: false,
            type: Boolean,
            default: undefined
        },

        isEditing: {
            required: false,
            type: Boolean,
            default: false,
        },

        id:{
            required: true,
            type: String
        },

        required: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const internalValue = computed({
            get: () => {
                return props.value;
            },
            set: (newValue) => {
                context.emit('update:value', newValue);
            }
        });

        return {
            internalValue
        }
    },
})
</script>
